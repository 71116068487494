var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-wrapper"},[(_vm.role !== 'exercise')?_c('div',[_c('div',{staticClass:"add-btn-wrapper"},[_c('h3',[_vm._v("Danh sách câu hỏi")]),_c('div',{staticClass:"add-btn"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewDetail()}}},[_vm._v("Tạo câu hỏi mới ")])],1)]),(_vm.questionsByGroup.length === 0)?_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"priority_high","outlined":""}},[_vm._v(" Chưa có dữ liệu hiển thị ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 custom-size-img",attrs:{"headers":_vm.headers1,"items":_vm.questionsByGroup},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewDetail(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,1165307663)})],1):_vm._e(),(_vm.role === 'exercise')?_c('div',[_c('div',{staticClass:"add-btn-wrapper"},[_c('h3',[_vm._v("Danh sách câu hỏi")]),_c('div',{staticClass:"add-btn"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewDetail()}}},[_vm._v("Tạo câu hỏi mới ")])],1)]),(_vm.questionsByGroupEx.length === 0)?_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"priority_high","outlined":""}},[_vm._v(" Chưa có dữ liệu hiển thị ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 custom-size-img",attrs:{"headers":_vm.headers,"items":_vm.questionsByGroupEx},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewDetail(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,1165307663)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }