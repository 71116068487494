<template>
  <div class="question-wrapper">
<!--    <h4 v-html="groupQuestion.title" class="text-center">-->
<!--    </h4>-->
    <div v-if="role !== 'exercise'">
      <div class="add-btn-wrapper">
        <h3>Danh sách câu hỏi</h3>
        <div class="add-btn">
          <v-btn color="primary" @click="viewDetail()"
          >Tạo câu hỏi mới
          </v-btn>
        </div>
      </div>
      <v-alert v-if="questionsByGroup.length === 0" :value="true" color="warning" icon="priority_high" outlined>
        Chưa có dữ liệu hiển thị
      </v-alert>
      <v-data-table :headers="headers1" :items="questionsByGroup" class="elevation-1 custom-size-img">
        <template v-slot:item.content="{ item }">
          <div v-html="item.content"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="viewDetail(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-if="role === 'exercise'">
      <div class="add-btn-wrapper">
        <h3>Danh sách câu hỏi</h3>
        <div class="add-btn">
          <v-btn color="primary" @click="viewDetail()"
          >Tạo câu hỏi mới
          </v-btn>
        </div>
      </div>
      <v-alert v-if="questionsByGroupEx.length === 0" :value="true" color="warning" icon="priority_high" outlined>
        Chưa có dữ liệu hiển thị
      </v-alert>
      <v-data-table :headers="headers" :items="questionsByGroupEx" class="elevation-1 custom-size-img">
        <template v-slot:item.content="{ item }">
          <div v-html="item.content"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="viewDetail(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {GET_QUESTION_BY_GROUP, DELETE_QUESTION, GET_QUESTION_BY_GROUP_EX} from "@/store/questions.module";
import {mapGetters} from "vuex";
import {GET_GROUP_QUESTION_DETAIL} from "@/store/group-questions.module";

export default {
  data() {
    return {
      role: this.$route.query.role ?? '',
      exam_config_id: this.$route.query.exam_config_id,
      group_question_id: this.$route.query.group_question_id,
      part_id: this.$route.query.part_id,
      headers: [
        {text: "Id", value: "id"},
        {text: "Nội dung câu hỏi", value: "content"},
        {text: "Index", value: "index"},
        {text: "Ngày tạo", value: "created_at"},
        {text: "Thao tác ", value: "actions"},
      ],
      headers1: [
        {text: "Id", value: "id"},
        {text: "Nội dung câu hỏi", value: "content"},
        {text: "Index", value: "index"},
        {text: "Ngày tạo", value: "created_at"},
        {text: "Thao tác ", value: "actions"},
      ],
    }
  },
  computed: {
    ...mapGetters({
      questionsByGroup: "questionListByGroup",
      questionsByGroupEx: "questionListByGroupEx",
      groupQuestion: "groupQuestionDetail"
    }),
  },
  async created() {
    await this.fetchQuestionList();
    if (this.role != 'exercise') {
      await this.$store.dispatch(GET_GROUP_QUESTION_DETAIL, {
        group_question_id: this.group_question_id
      });
    }

  },
  methods: {
    fetchQuestionList() {
      if (this.role == 'exercise'){
        this.$store.dispatch(GET_QUESTION_BY_GROUP_EX, {
          group_question_id: this.group_question_id
        });
      } else {
        this.$store.dispatch(GET_QUESTION_BY_GROUP, {
          group_question_id: this.group_question_id
        });
      }
    },
    viewDetail(item) {
      let routeData = null;
      if (item) {
        if (this.role == 'exercise') {
          console.log(item, 'item')
          routeData = this.$router.resolve({
            path: "/question/detail",
            query: {
              exercise_id: this.$route.query.exercise_id,
              question_id: item.id,
              group_question_id: this.group_question_id,
            },
          });
        } else {
          routeData = this.$router.resolve({
            path: "/question/detail",
            query: {
              part_id: this.part_id,
              question_id: item.id,
              exam_config_id: this.exam_config_id,
              group_question_id: this.group_question_id,
            },
          });
        }
      } else {
        if (this.role == 'exercise') {
          routeData = this.$router.resolve({
            path: "/question/detail",
            query: {
              exercise_id: this.$route.query.exercise_id,
              group_question_id: this.group_question_id,
            },
          });
        } else {
          routeData = this.$router.resolve({
            path: "/question/detail",
            query: {
              part_id: this.part_id,
              exam_config_id: this.exam_config_id,
              group_question_id: this.group_question_id,
            },
          });
        }
      }
      window.open(routeData.href, '_self');
    },
    deleteItem(item) {
      let payload = {};
      if (this.role == 'exercise') {
          payload = {
          question_id: item.id,
          type: 2,
        }
      } else {
        payload = {
          question_id: item.id,
          type: 1,
        }
      }
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.$store
          .dispatch(DELETE_QUESTION, payload)
          .then((data) => {
            if (data.status) {
              this.fetchQuestionList()
            }
          });
      this.$router.reload()
    }
  }
}
</script>

<style lang="scss">
.question-wrapper {
  padding: 20px;
}

.add-btn-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 10px;

  .add-btn {
    display: flex;
    justify-content: flex-end;*

    button {
      width: 200px;
    }
  }
}
.custom-size-img {
  img {
    margin-top: 20px;
    width: 300px;
  }
}
</style>
